<app-header></app-header>

<!-- <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Kidia Oneexpress</p>
        <p style="text-align: justify;">
          Kidia One Express
          We do provide scheduled daily bus transportation from Dar es Salaam to Moshi, Arusha, Dodoma, Tabora, Kaliua, Uvinza, Kasulu, Kigoma and from these region to Dar es Salaam daily. Our booking Bus Offices are in Dar es Salaam, Moshi, Boma Ng'ombe, Kiboriloni, Arusha, Morogoro, Kasuluand Kigoma.
      </div>
    </div>
  </div> -->
<div class="about-section section container">
  <div class="row">
    <div class="col-md-6">
      <img
        class="img-fluid"
        src="../../../assets/Buscar Bus Images/Buscar Malindi to Nairobi.jpg"
        alt="Buscar online booking"
      />
    </div>
    <div class="col-md-6">
      <h3>About Buscar </h3>
      <p>
        Providing luxurious buses, on board entertainment, individual seat belts
        and many others BUSCAR has truly embraced a new way of road travel in
        East Africa. With an online booking system for fast and accurate
        bookings, giving our passengers the luxury to plan their journeys with
        us.
      </p>
      <h4>The King of Comfort</h4>
      <p>
        With spacious and comfortable interior, our buses offer individual
        seatbelts. VIP seats, plug points and many other creature comforts
        making your journey with us a memorable one. Our Passengers will be able
        to watch a selection of movies inside the bus using the built in
        entertainment system. Our crew are well trained. They are friendly with
        the customers. The drivers are able to communicate well with the
        passengers. They are experienced in driving the bus and are very
        disciplined.
      </p>
      <h4>Safety our Priority</h4>
      <p>
        Our buses are designed with emergency exits located on positions easily
        accessible in case of emergencies. By putting safety our outmost
        priority our buses are well maintained and our crew are well trained to
        give you the best possible safety assurance.
      </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
