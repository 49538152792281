<app-header></app-header>

<div class="blog-section section container">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <h4>Contact Us</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field appearance="outline">
                <mat-label>Country Code</mat-label>
                <input matInput placeholder="Country Code" />
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field appearance="outline">
                <mat-label>Phone</mat-label>
                <input matInput placeholder="Phone" />
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" />
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Subject</mat-label>
                <input matInput placeholder="Subject" />
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <textarea matInput placeholder="Message" rows="5"></textarea>
              </mat-form-field>
            </div>
            <div class="col-sm-12">
              <button class="sendMessageBtn">Send Message</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" style="margin-top: 100px">
  <div class="row">
    <div *ngFor="let office of offices" class="col-sm-3 text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="icon icon-tabler icons-tabler-outline icon-tabler-building-estate"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 21h18" />
        <path d="M19 21v-4" />
        <path d="M19 17a2 2 0 0 0 2 -2v-2a2 2 0 1 0 -4 0v2a2 2 0 0 0 2 2z" />
        <path d="M14 21v-14a3 3 0 0 0 -3 -3h-4a3 3 0 0 0 -3 3v14" />
        <path d="M9 17v4" />
        <path d="M8 13h2" />
        <path d="M8 9h2" />
      </svg>
      <p style="font-weight: bold">{{ office.name }}</p>
      <p>{{ office.phone }}</p>
      <hr />
    </div>
  </div>
</div>

<app-footer></app-footer>
