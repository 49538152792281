<app-header></app-header>

<div class="container" style="height: 100%; padding: 40px 0">
  <div class="row">
    <div class="col-md-4 col-sm-6" *ngFor="let image of images; let i = index" @fadeIn>
      <div class="gallery-item" (click)="openModal(image, i)">
        <img [src]="image.src" [alt]="image.alt" class="img-fluid" />
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div [ngClass]="{ modal: true, show: isModalOpen }" (click)="closeModal()">
  <span class="close" (click)="closeModal()">&times;</span>
  <div class="modal-content" (click)="$event.stopPropagation()">
    <div class="carousel">
      <div class="carousel-inner">
        <div *ngFor="let img of images; let i = index" [ngClass]="{'active': selectedImageIndex === i}">
          <img *ngIf="selectedImageIndex === i" [src]="img.src" [alt]="img.alt" class="modal-image" />
        </div>
      </div>
      <a class="carousel-control-prev" (click)="prevImage()">&lt;</a>
      <a class="carousel-control-next" (click)="nextImage()">&gt;</a>
    </div>
  </div>
</div>

<app-footer></app-footer>
